import React, { Component } from 'react';
import SplashImage from './components/splash-image';
import ServiceArea from './components/service-area';
import Details from './components/details';
import Safety from './components/safety';
import HowTo from './components/how-to';
import Download from './components/download';
import history from '../../helpers/history';
import api from '../../helpers/api';

const cities = {
  wellington: {
    regionId: 1,
    instructionGroupId: 1,
    name: 'Wellington',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/wellington-waterfront.jpg',
    catch: 'Ride the winds of the capital with Flamingos at your side.',
    pricing: ['$1 to unlock, then', '65c per minute'],
    hours: ['24 hours - Monday to Sunday', 'Courtenay Precinct restricted from 9pm Friday & Saturday'],
    center: { lat: -41.263102, lng:	174.794621 },
    zoom: 11,
    howTo: { scooter5: true, bike: true },
  },
  porirua: {
    regionId: 13,
    instructionGroupId: 14,
    name: 'Porirua',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/porirua-scooter-2.jpg',
    catch: 'Explore Porirua City (and Tawa) with Flamingo!',
    pricing: ['$1 to unlock, then', '65c per minute'],
    hours: ['24 hours - Monday to Sunday', ' '],
    center: { lat: -41.12700853505192, lng:	174.85471123997027 },
    zoom: 12,
    howTo: { scooter: true, bike: false },
  },
  auckland: {
    regionId: 2,
    instructionGroupId: 2,
    name: 'Auckland',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/flamingo-auckland.jpg',
    catch: 'The city of sails, and pink Flamingos.',
    pricing: ['$1 to unlock, then', '45c per minute'],
    hours: ['24 hours - Monday to Sunday', 'Curfew Areas restricted from 9pm Friday & Saturday'],
    center: { lat: -36.836178, lng: 174.773818 },
    zoom: 11,
    howTo: { scooter5: true },
  },
  christchurch: {
    regionId: 4,
    instructionGroupId: 4,
    name: 'Christchurch',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/christchurch.jpg',
    catch: 'Service ended 31 October 2021.',
    // catch: 'The garden city, where Flamingos flourish.',
    pricing: ['$1 to unlock, then', '45c per minute'],
    hours: ['5am to 12am - Monday to Sunday', ' '],
    center: { lat: -43.517211, lng: 172.634101 },
    zoom: 11,
    howTo: { scooter: true },
  },
  'palmerston-north': {
    regionId: 9,
    instructionGroupId: 11,
    name: 'Palmerston North',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/palmy.jpg',
    catch: 'From The Square to every corner of the city, Flamingos get you there.',
    pricing: ['$1 to unlock, then', '55c per minute'],
    hours: ['24 hours - Monday to Sunday', ' '],
    center: { lat: -40.356118, lng: 175.611069 },
    zoom: 12,
    howTo: { scooter: true },
  },
  waimakariri: {
    regionId: 5,
    instructionGroupId: 12,
    name: 'Waimakariri',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/waimakariri.jpg',
    catch: 'Rangiora, Kaiapoi, Woodend & Pegasus.',
    pricing: ['$1 to unlock, then', '55c per minute'],
    hours: ['24 hours - Monday to Sunday', ''],
    center: { lat: -43.33661891015118, lng: 172.6368327793517 },
    zoom: 12,
    howTo: { scooter: true },
  },
  dunedin: {
    regionId: 15,
    instructionGroupId: 17,
    name: 'Dunedin',
    image: 'https://storage.googleapis.com/flamingo-static/images/facade/dunedin.jpg',
    catch: 'From castles to coastlines, with Flamingos in between.',
    pricing: ['$1 to unlock, then', '45c per minute'],
    hours: ['24 hours - Monday to Sunday', ''],
    center: { lat: -45.862718333939675, lng: 170.5054860745519 },
    zoom: 12,
    howTo: { scooter: true },
  },
};

class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      city: cities[props.match.params.region],
      loadingInstructions: true,
      loadingRegion: true,
    };
    this.loadingRegion = this.loadingRegion.bind(this);
    this.loadInstructions = this.loadInstructions.bind(this);
  }

  componentDidMount() {
    const { city } = this.state;
    if (!city) {
      history.push('/');
    }
    document.title = `${city.name} | Flamingo`;
    this.loadingRegion(city.regionId);
    this.loadInstructions(city.instructionGroupId);
  }

  loadingRegion(regionId) {
    return api.get(`/facade/region/${regionId}`)
      .then((res) => this.setState({ region: res.data.data, loadingRegion: false }))
      .catch(() => {});
  }

  loadInstructions(instructionGroupId) {
    return api.get(`/facade/instruction/${instructionGroupId}`)
      .then((res) => this.setState({ instruction: res.data.data, loadingInstructions: false }))
      .catch(() => {});
  }

  render() {
    const {
      city,
      loadingRegion,
      loadingInstructions,
      region,
      instruction,
    } = this.state;

    if (!city) {
      history.push('/');
    }

    return (
      <div className="fm-city">
        <SplashImage city={city} />
        <ServiceArea city={city} region={region} loading={loadingRegion} />
        <Details city={city} />
        <Safety city={city} instruction={instruction} loading={loadingInstructions} />
        <HowTo vehicles={city.howTo} />
        <Download />
      </div>
    );
  }
}

export default City;
